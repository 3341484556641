import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/root/Layout"
import Seo from "../../components/root/Seo"
import Newsletter from "../../components/modules/Newsletter"
import CheckCircle from "../../../assets/heroicons-0.4.1/optimized/solid/check-circle.svg"

export default function About() {
  return (
    <Layout>
      <Seo title="Thankyou" />
      <div className="">
        <div className="container-lg py-8 md:py-16 lg:py-24">
          <h1 className="text-3xl md:text-5xl leading-tight font-bold">
            Thank your applying,
          </h1>
          <h1 className="text-3xl md:text-5xl leading-tight font-bold text-brand-green mb-3">
            you’re one step closer to reaching your goals!
          </h1>
          <h2 className="text-2xl md:text-3xl mb-5">
            Here's what happens next:
          </h2>

          <ul>
            <li>
              <div className="flex items-center mb-3">
                <CheckCircle className={`text-brand-green glyph-md mr-4`} />
                <p className="text-lg  w-full">
                  We’ll use the information you provided to build out your
                  unique profile
                </p>
              </div>
            </li>
            <li>
              <div className="flex items-center mb-3">
                <CheckCircle className={`text-brand-green glyph-md mr-4`} />
                <p className="text-lg w-full">
                  Leave it to us to now find you the best mentor
                </p>
              </div>
            </li>
            <li>
              <div className="flex items-center mb-3">
                <CheckCircle className={`text-brand-green glyph-md mr-4`} />
                <p className="text-lg  w-full">
                  We’ll get back to you within max 1 week
                </p>
              </div>
            </li>
          </ul>
        </div>
        {/* <Newsletter />
        <div className="bg-brand-blue sm:py-8"/> */}
      </div>
    </Layout>
  )
}
