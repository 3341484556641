import React from "react"
import Newsletter from "../elements/Newsletter"
import Mail from "../../../assets/heroicons-0.4.1/optimized/outline/mail.svg"

const NewsletterModule = () => {
  return (
    <div className="bg-brand-blue">
      <div className="container-lg relative py-8 md:py-16 text-white">
          <h1 className="text-xl">Want to be kept in the loop?</h1>
          <h2 className="text-4xl md:text-5xl font-bold">Join our newsletter.</h2>
          <p>To meet the mentors, hear our success stories and upcoming initiatives subscribe to our newsletter. </p>
          <p className="font-bold mb-3">Never any spam and you can unsubscribe at any time.</p>
          <div className="grid grid-cols-1 md:grid-cols-3">
            <div className="md:col-span-2">
              <Newsletter noLabel text="text-white" id="module"/>
            </div>
            <div className="hidden md:block absolute top-0 right-0 opacity-25 mt-8 transform -rotate-45 -mr-16" style={{height:325, width:325}}>
              <Mail />
            </div>
          </div>
      </div>
    </div>
  )
}

export default NewsletterModule
